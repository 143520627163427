import React from "react";

function SmallButton(
  props: {
    label?: string;
    children?: JSX.Element | JSX.Element[];
    right?: boolean;
    showSm?: boolean;
  } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) {
  const props1 = {
    ...props,
  };
  delete props1.right;
  delete props1.showSm;
  delete props1.label;
  return (
    <button
      {...props1}
      className={
        "flex items-center border py-1 px-2 gap-1 rounded-full w-fit hover:shadow select-none text-gray-700 hover:text-black text-sm " +
        (props.className || "")
      }
    >
      {!props.right && !!props.label && (
        <span
          className={`${
            props.showSm ? "" : "hidden sm:block"
          } font-semibold truncate`}
        >
          {props.label}
        </span>
      )}
      <span>{props.children}</span>
      {!!props.right && !!props.label && (
        <span
          className={`${
            props.showSm ? "" : "hidden sm:block"
          } font-semibold truncate`}
        >
          {props.label}
        </span>
      )}
    </button>
  );
}

export default SmallButton;
