import React from "react";

function Loading(props: { className?: string; enabled?: boolean }) {
  return (
    <div className={props.className}>
      <div
        className={`animate-pulse bg-blue-500 transition-all duration-75 ease-out ${
          props.enabled === true || props.enabled === undefined
            ? "block h-1 opacity-0"
            : "hidden h-0 opacity-100"
        }`}
      />
    </div>
  );
}

export default Loading;
