import { Money } from "../../core/model/Money";
import { CountedResult, Page } from "../../core/model/Pagination";
import { HttpClient, queryToString } from "../../core/services/HttpClient";
import { Expense, ExpenseType } from "../model/Expense";

export class ExpenseService {
  static async search(
    search: string,
    page: Page
  ): Promise<CountedResult<Expense>> {
    const query = {
      search: search || undefined,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<Expense>>(
      `/expenses/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async ofYear(
    expenseTypeId: string,
    year: number
  ): Promise<CountedResult<Expense> & { total: Money }> {
    const query = {
      year: year || undefined,
      typeid: expenseTypeId,
    };

    const res = await HttpClient.get<CountedResult<Expense> & { total: Money }>(
      `/expenses/year?${queryToString(query)}`
    );
    return res.data;
  }
  static async get(id: string): Promise<Expense> {
    const res = await HttpClient.get<Expense>(`/expenses/id?id=${id}`);
    return res.data;
  }
  static async save(expense: Expense): Promise<Expense> {
    const res = await HttpClient.post<Expense>(`/expenses`, expense);
    return res.data;
  }
  static async delete(expense: Expense): Promise<Expense> {
    const res = await HttpClient.delete<Expense>(`/expenses?id=${expense.id}`);
    return res.data;
  }

  static async searchTypes(
    search: string,
    page: Page
  ): Promise<CountedResult<ExpenseType>> {
    const query = {
      search: search || undefined,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<ExpenseType>>(
      `/expenses/types/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async getType(id: string): Promise<ExpenseType> {
    const res = await HttpClient.get<ExpenseType>(
      `/expenses/types/id?id=${id}`
    );
    return res.data;
  }
  static async saveType(expense: ExpenseType): Promise<ExpenseType> {
    const res = await HttpClient.post<ExpenseType>(`/expenses/types`, expense);
    return res.data;
  }
}
