import React from "react";
import { useTranslation } from "react-i18next";
import ComboBoxAsync from "../../../core/components/inputs/ComboBoxAsync";
import { Provider } from "../../model/Provider";
import { ProviderService } from "../../services/ProviderService";

function ProviderSelector(props: {
  className?: string;
  value?: string;
  onChange: (v?: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <ComboBoxAsync
      className={props.className}
      fetch={(p, s) => ProviderService.search(s || "", p)}
      fetchSingle={ProviderService.get}
      value={props.value}
      onChange={props.onChange}
      getId={(x) => x?.id}
      textBuilder={(v: Provider) => v?.name}
      entryBuilder={(v, { active, selected }) => (
        <span className={`relative block truncate font-normal`}>
          <div className="leading-4 font-medium">{v.name}</div>
          <div className="text-[14px] leading-[14px]">
            {`${t("entities.tel")}: ${v.email || t("core.na")}`}
          </div>
          <div className="text-[12px] leading-[12px]">
            {`${t("entities.email")}: ${v.telefone || t("core.na")} `}
          </div>
        </span>
      )}
    />
  );
}

export default ProviderSelector;
