import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CgSearch } from "react-icons/cg";
import { FiArrowLeft, FiArrowRight, FiX } from "react-icons/fi";
import ClientListItem from "../../../entitymanagement/components/lists/ClientListItem";
import EmployeListItem from "../../../entitymanagement/components/lists/EmployeListItem";
import ProviderListItem from "../../../entitymanagement/components/lists/ProviderListItem";
import { ClientService } from "../../../entitymanagement/services/ClientService";
import { EmployeService } from "../../../entitymanagement/services/EmployeService";
import { ProviderService } from "../../../entitymanagement/services/ProviderService";
import ExpenseTypeListItem from "../../../expensesmanagement/components/lists/ExpenseTypeListItem";
import { ExpenseService } from "../../../expensesmanagement/services/ExpenseService";
import ProductListItem from "../../../productmanagement/components/ProductListItem";
import { ProductService } from "../../../productmanagement/services/ProductService";
import ServiceListItem from "../../../servicesmanagement/components/ServiceListItem";
import { EventService } from "../../../servicesmanagement/services/EventService";
import { usePagination } from "../../hooks/Pagination";
import SmallButton from "../inputs/SmallButton";

function CommandPallete(props: {}) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    open: false,
    search: "",
    showMore: null as null | number,
  });

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "k") {
        setState({
          open: true,
          search: "",
          showMore: null,
        });
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      if (e.key === "Escape" && state.open) {
        setState({
          open: false,
          search: "",
          showMore: null,
        });
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    [state.open]
  );
  const onSearchEvent = useCallback(
    () =>
      setState({
        open: true,
        search: "",
        showMore: null,
      }),
    []
  );
  useEffect(() => {
    window.addEventListener("keydown", handleKey as any);
    window.addEventListener("onSearch", onSearchEvent as any);
    return () => {
      window.removeEventListener("keydown", handleKey as any);
      window.removeEventListener("onSearch", onSearchEvent as any);
    };
  }, [handleKey, onSearchEvent]);

  const services = usePagination({
    fetch: (p, search) => EventService.search(p, { search }),
  });
  const clients = usePagination({
    fetch: (p, s) => ClientService.search(s, p),
  });
  const provider = usePagination({
    fetch: (p, s) => ProviderService.search(s, p),
  });
  const employe = usePagination({
    fetch: (p, s) => EmployeService.search(s, p),
  });
  const expense = usePagination({
    fetch: (p, s) => ExpenseService.searchTypes(s, p),
  });
  const products = usePagination({
    fetch: (p, s) => ProductService.search(p, s),
  });

  useEffect(() => {
    if (!state.search) return;
    services.onSearch(state.search);
    clients.onSearch(state.search);
    provider.onSearch(state.search);
    employe.onSearch(state.search);
    expense.onSearch(state.search);
    products.onSearch(state.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.search]);

  const entries = useMemo(() => {
    return [
      {
        title: "Serviços",
        entries: services.values.map((e) => (
          <ServiceListItem key={`commpall-serv-${e.id}`} event={e} />
        )),
      },
      {
        title: "Clientes",
        entries: clients.values.map((e) => (
          <ClientListItem key={`commpall-cli-${e.id}`} client={e} />
        )),
      },
      {
        title: "Fornecedores",
        entries: provider.values.map((e) => (
          <ProviderListItem key={`commpall-provider-${e.id}`} provider={e} />
        )),
      },
      {
        title: "Empregados",
        entries: employe.values.map((e) => (
          <EmployeListItem key={`commpall-emplou-${e.id}`} employe={e} />
        )),
      },
      {
        title: "Despesa Indireta",
        entries: expense.values.map((e) => (
          <ExpenseTypeListItem
            key={`commpall-expense-${e.id}`}
            expenseType={e}
          />
        )),
      },
      {
        title: "Produtos",
        entries: products.values.map((e) => (
          <ProductListItem key={`commpall-product-${e.id}`} product={e} />
        )),
      },
    ];
  }, [
    clients.values,
    employe.values,
    expense.values,
    products.values,
    provider.values,
    services.values,
  ]);

  if (!state.open) return <div className="hidden"></div>;

  return (
    <>
      <div
        className="absolute inset-0 z-30 backdrop-blur-[0.5px]"
        onClick={(e) =>
          setState({
            open: false,
            search: "",
            showMore: null,
          })
        }
      />
      <div
        className="absolute inset-0 z-30 flex py-4 md:py-48 px-4 overflow-hidden items-start justify-center"
        onClick={(e) =>
          setState({
            open: false,
            search: "",
            showMore: null,
          })
        }
      >
        <div
          className="grid grid-rows-[auto,minmax(0px,1fr)] grid-cols-1 items-stretch backdrop-blur-lg shadow-lg border bg-gray-200/30 rounded-xl max-w-screen-sm w-full max-h-full overflow-hidden"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="group flex gap-2 items-center text-lg p-4 border-b">
            <CgSearch
              className="text-gray-400 group-focus-within:text-black"
              size={28}
            />
            <input
              className="flex-grow h-full text-gray-500 focus:text-black bg-transparent outline-none font-medium tracking-wide min-w-0"
              placeholder={t("core.search")}
              autoFocus={true}
              value={state.search}
              onChange={(e) =>
                setState({
                  ...state,
                  search: e.target.value,
                  showMore: null,
                })
              }
            />
            <button
              className=""
              onClick={() =>
                setState({
                  open: false,
                  search: "",
                  showMore: null,
                })
              }
            >
              <FiX />
            </button>
          </div>
          <div className={"grid h-auto "}>
            {!!state.search && state.showMore === null && (
              <div className="p-4 overflow-y-auto">
                {entries
                  .filter((x) => x.entries.length > 0)
                  .map((x, i) => (
                    <>
                      <div
                        key={`commandpal-title-${x.title}`}
                        className="border-b px-2 pb-2 flex justify-between"
                      >
                        <h5 className="text-gray-800 ">{x.title}</h5>
                        <SmallButton
                          onClick={() =>
                            setState({
                              ...state,
                              showMore: state.showMore === i ? null : i,
                            })
                          }
                          className=""
                          label={
                            state.showMore === i
                              ? t("core.actions.less")
                              : t("core.actions.more")
                          }
                          right={state.showMore === i}
                        >
                          {state.showMore === i ? (
                            <FiArrowLeft />
                          ) : (
                            <FiArrowRight />
                          )}
                        </SmallButton>
                      </div>
                      <div
                        key={`commandpal-title-${x.title}-container`}
                        onClick={() =>
                          setState({
                            open: false,
                            search: "",
                            showMore: null,
                          })
                        }
                      >
                        {x.entries.filter((_, i) => i < 3)}
                      </div>
                    </>
                  ))}
                {!entries.filter((x) => x.entries.length > 0).length && (
                  <div className="text-gray-500">
                    {t("core.info.not_found_list")}
                  </div>
                )}
              </div>
            )}
            {state.showMore !== null && (
              <div className="overflow-y-auto p-4">
                <div className="px-2 pb-2 flex flex-nowrap justify-between ">
                  <h6>
                    {
                      entries.filter((x) => x.entries.length > 0)[
                        state.showMore
                      ].title
                    }
                  </h6>
                  <SmallButton
                    onClick={() =>
                      setState({
                        ...state,
                        showMore: null,
                      })
                    }
                    className=""
                    label={t("core.actions.close")}
                    right
                  >
                    <FiX />
                  </SmallButton>
                </div>
                <div className="">
                  {
                    entries.filter((x) => x.entries.length > 0)[state.showMore]
                      .entries
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommandPallete;
