import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { basePriceToFinal, moneyToText } from "../../core/model/Money";
import { Product } from "../model/Product";
import { PRODUCTPAGE_PRODUCT_QUERY } from "../pages/ProductsPage";

function ProductListItem(props: {
  product: Product;
  onClick?: MouseEventHandler;
}) {
  const { t } = useTranslation();
  return (
    <NavLink
      className="hover:bg-gray-100 p-2 cursor-pointer flex flex-nowrap items-center"
      onClick={props.onClick}
      to={`/products?search=${props.product.name}&${PRODUCTPAGE_PRODUCT_QUERY}=${props.product.id}`}
    >
      <div className="flex-grow">
        <h5 className="leading-5 font-semibold">{`${props.product.name}`}</h5>
        <div className="text-sm leading-4 truncate text-gray-500">
          {props.product.date}
        </div>
      </div>
      <div>
        <div className="truncate font-semibold text-right">{`${moneyToText(
          props.product,
          t
        )}`}</div>
        <div className="text-sm truncate leading-4 text-gray-500 font-semibold text-right">{`${moneyToText(
          basePriceToFinal(props.product),
          t
        )} (${props.product.iva}%)`}</div>
      </div>
    </NavLink>
  );
}

export default ProductListItem;
