import { CountedResult, Page } from "../../core/model/Pagination";
import { HttpClient, queryToString } from "../../core/services/HttpClient";
import { Client } from "../model/Client";
import { Entity } from "../model/Entity";

export class ClientService {
  static async search(
    search: string,
    page: Page
  ): Promise<CountedResult<Client>> {
    const query = {
      search: search || undefined,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<Client>>(
      `/clients/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async get(id: string): Promise<Client> {
    const res = await HttpClient.get<Client>(`/clients/id?id=${id}`);
    return res.data;
  }
  static async save(client: Client): Promise<Client> {
    const res = await HttpClient.post<Client>(`/clients`, client);
    return res.data;
  }
  static async toggleFavorite(c: Entity): Promise<Client> {
    const res = await HttpClient.post<Client>(
      `/clients/id/${c.id}/favorite`,
      {}
    );
    return res.data;
  }
}
