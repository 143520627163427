const APIERR_ID = "n329ytj0gd9j90009tvwh8tv9tvewtv";

export class APIError extends Error {
  public id = APIERR_ID;
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message: string) {
    super(message);
  }
}

export const errorToString = (err: any) => {
  if ("id" in err && err.id === APIERR_ID) {
    const e = err as APIError;
    return e.message;
  }
  return "code.500";
};
