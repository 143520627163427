export type Cookie = {
  key: string;
  value: string;
};

export const getCookie = (name: string): Cookie | undefined => {
  const cookies = getCookies();
  return cookies.find((x) => x.key === name);
};

export const getCookies = (): Cookie[] =>
  document.cookie
    .split(";")
    .map((x) => x.trim())
    .map((x) => x.split("="))
    .filter((x) => x.length >= 2)
    .map((x) => ({
      key: x[0].trim(),
      value: x[1].trim(),
    }));

export const setCookie = (
  cookie: Cookie,
  options?: {
    durationMinutes?: number;
  }
) => {
  const values = [cookie.key + "=" + cookie.value, "path=/"];
  if (options?.durationMinutes) {
    const d = new Date();
    d.setTime(d.getTime() + options.durationMinutes * 60 * 1000);
    values.push("expires=" + d.toUTCString());
  }
  document.cookie = values.join(";");
};
