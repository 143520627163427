import React, { MouseEventHandler } from "react";
import { Client } from "../../model/Client";
import { ClientService } from "../../services/ClientService";
import EntityListItem from "./EntityListItem";

function ClientListItem(props: {
  client: Client;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <EntityListItem
      baseUrl="/clients"
      entity={props.client}
      onClick={props.onClick}
      toggleFn={ClientService.toggleFavorite}
    />
  );
}

export default ClientListItem;
