import React, { ComponentType, Suspense, useEffect, useState } from "react";
import NavBar from "./core/components/application/NavBar";
import { useRoutes } from "react-router-dom";
import {
  setApplicationConfig,
  useApplicationConfigSafe,
} from "./core/hooks/ApplicationConfig";
import ApplicationConfigPage from "./core/pages/ApplicationConfigPage";
import { ApplicationConfigService } from "./core/services/ApplicationConfigService";
import Loading from "./core/components/utils/Loading";
import GenericSuspend from "./core/components/utils/GenericSuspend";
import CommandPallete from "./core/components/application/CommandPallete";
import {
  setAuthenticatedUser,
  useAuthenticatedUserSafe,
  VERIFY_AUTH_EVENT,
} from "./core/hooks/AuthenticatedUser";
import LoginPage from "./core/pages/LoginPage";
import { SERVICEWORKER_WAITING_EVENT } from "./core/utils/ServiceWorker";
import Drawer from "./core/components/application/Drawer";

const HomePage = React.lazy(() => import("./core/pages/HomePage"));
const CalendarPage = React.lazy(
  () => import("./servicesmanagement/pages/CalendarPage")
);
const ClientsPage = React.lazy(
  () => import("./entitymanagement/pages/ClientsPage")
);
const ProvidersPage = React.lazy(
  () => import("./entitymanagement/pages/ProviderPage")
);
const EmployeesPage = React.lazy(
  () => import("./entitymanagement/pages/EmployeesPage")
);
const EnvoicesPage = React.lazy(
  () => import("./envoicemanagement/pages/EnvoicesPage")
);
const ProductsPage = React.lazy(
  () => import("./productmanagement/pages/ProductsPage")
);
const EventPage = React.lazy(
  () => import("./servicesmanagement/pages/EventPage")
);
const ExpenseTypesPage = React.lazy(
  () => import("./expensesmanagement/pages/ExpenseTypesPage")
);
const BalancePage = React.lazy(
  () => import("./statsmanagement/pages/BalancePage")
);
const ConfigPage = React.lazy(() => import("./core/pages/ConfigPage"));

function Router(props: {}) {
  const routes = useRoutes([
    {
      index: true,
      element: <HomePage />,
    },
    {
      path: "calendar",
      element: <CalendarPage />,
    },
    {
      path: "event/:eventId/*",
      element: <EventPage />,
    },
    {
      path: "clients/*",
      element: <ClientsPage />,
    },
    {
      path: "providers/*",
      element: <ProvidersPage />,
    },
    {
      path: "employees/*",
      element: <EmployeesPage />,
    },
    {
      path: "envoices/*",
      element: <EnvoicesPage />,
    },
    {
      path: "products/*",
      element: <ProductsPage />,
    },
    {
      path: "expenses/*",
      element: <ExpenseTypesPage />,
    },
    {
      path: "balance/*",
      element: <BalancePage />,
    },
    {
      path: "configurations/*",
      element: <ConfigPage />,
    },
  ]);
  return routes;
}

function App() {
  const [pendingUpdate, setPendingUpdate] = useState(false);

  useEffect(() => {
    const fn = async () => {
      setPendingUpdate(true);
    };
    document.addEventListener(SERVICEWORKER_WAITING_EVENT, fn);
    return () => {
      document.removeEventListener(SERVICEWORKER_WAITING_EVENT, fn);
    };
  }, []);
  const skipWaiting = async () => {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach((r) =>
        r.waiting?.postMessage({ type: "SKIP_WAITING" })
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const appConfig = useApplicationConfigSafe();
  const authUser = useAuthenticatedUserSafe();
  useEffect(() => {
    const fn = async () => {
      try {
        const res = await ApplicationConfigService.getAuthenticatedUser();
        setAuthenticatedUser(res);
      } catch (e) {
        setAuthenticatedUser(null);
      }
    };
    document.addEventListener(VERIFY_AUTH_EVENT, fn);
    return () => {
      document.removeEventListener(VERIFY_AUTH_EVENT, fn);
    };
  }, []);
  if (!authUser) {
    return <LoginPage />;
  }
  return (
    <>
      <NavBar
        noDrawer={!appConfig}
        pendingUpdate={pendingUpdate}
        skipWaiting={skipWaiting}
      />
      <div className="bg-gray-50 min-h-screen max-h-screen overflow-hidden flex flex-row flex-warp pt-10">
        {!!appConfig && <CommandPallete />}
        <Drawer
          open
          pendingUpdate={pendingUpdate}
          skipWaiting={skipWaiting}
          className="hidden lg:block"
        />
        <GenericSuspend>
          {!appConfig ? <ApplicationConfigPage /> : <Router />}
        </GenericSuspend>
      </div>
    </>
  );
}

const AppLazy = React.lazy(
  async (): Promise<{ default: ComponentType<any> }> => {
    const minStart = new Date().getTime() + 1 * 1000;
    try {
      const res = await ApplicationConfigService.getAuthenticatedUser();
      setAuthenticatedUser(res);
    } catch (e) {
      setAuthenticatedUser(null);
    }
    try {
      const res = await ApplicationConfigService.getConfig();
      setApplicationConfig(res);
    } catch (e) {
      setApplicationConfig(null);
    }
    const currTime = new Date().getTime();
    if (currTime < minStart) {
      await new Promise((r) => {
        setTimeout(r, minStart - currTime);
      });
    }
    return {
      default: App,
    };
  }
);
function AppView(props: {}) {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center justify-center h-screen w-screen">
          <div className="max-w-[30rem] mx-8">
            <img src="/logo.svg" alt="logo" />
          </div>
          <h1 className="text-center text-2xl font-bold tracking-wide sr-only">
            Solestício
          </h1>
          <Loading className="mt-2 w-64" />
        </div>
      }
    >
      <AppLazy />
    </Suspense>
  );
}
export default AppView;
