import React, { useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { usePagination } from "../../hooks/Pagination";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";
import { CountedResult, Page } from "../../model/Pagination";

function ComboBoxAsync<T>(props: {
  className?: string;
  value?: string;
  placeholder?: string;
  onChange: (c?: string) => void;
  fetch: (p: Page, search?: string) => Promise<T[] | CountedResult<T>>;
  fetchSingle: (id: string) => Promise<T>;
  getId: (v?: T) => string | undefined;
  textBuilder: (v: T) => string;
  entryBuilder: (
    v: T,
    o: { selected: boolean; active: boolean }
  ) => JSX.Element | JSX.Element[];
}) {
  const { t } = useTranslation();
  const [client, setClient] = useState<T>();
  const { values, allValues, search, onSearch, handleScroll } = usePagination({
    fetch: (p, s) => props.fetch(p, s),
  });

  useEffect(() => {
    const fetch = async () => {
      const res = await props.fetchSingle(props.value || "");
      setClient(res);
    };

    if (!props.value) {
      setClient(undefined);
      return;
    }

    if (props.value === props.getId(client)) return;

    const clientObj = allValues.find((x) => props.getId(x) === props.value);
    if (clientObj) {
      setClient(clientObj);
    } else {
      fetch();
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const onChange = (cli: T) => {
    setClient(cli);
    props.onChange(props.getId(cli));
  };

  return (
    <Combobox value={client} onChange={onChange}>
      <div
        className={
          "relative outline-2 focus-within:outline outline-blue-600 rounded pr-8 " +
          props.className
        }
      >
        <Combobox.Input
          className="w-full outline-none"
          onChange={(event) => onSearch(event.target.value)}
          value={search}
          placeholder={props.placeholder}
          displayValue={props.textBuilder}
        />
        <Combobox.Button
          as="div"
          className="absolute -right-1 inset-y-0 w-8 flex items-center justify-center text-gray-800"
        >
          <FiChevronDown />
        </Combobox.Button>
      </div>
      <Transition
        as="div"
        className="relative z-10"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        afterLeave={() => onSearch("")}
      >
        <Combobox.Options
          as="div"
          onScroll={handleScroll}
          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {values.length === 0 && search !== "" ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              {t("core.info.not_found_list")}
            </div>
          ) : (
            values.map((value) => (
              <Combobox.Option
                as="div"
                key={"clisel-option-" + props.getId(value)}
                className={({ active, selected }) =>
                  `relative cursor-default select-none p-2 bg-white ${
                    selected ? "bg-blue-500 text-white" : ""
                  } ${active ? "bg-gray-100 " : ""}`
                }
                value={value}
                id={props.getId(value)}
              >
                {({ selected, active }) => (
                  <span className={`relative block truncate font-normal`}>
                    {props.entryBuilder(value, { selected, active })}
                  </span>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
}

export default ComboBoxAsync;
