import i18n, { Formatter } from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { format, Locale, parse, parseISO } from "date-fns";
import * as locales from "date-fns/locale";

// const languages = ["en", "pt"];

const fallbackLng = "pt";

const localesFixed = Object.fromEntries(Object.entries(locales));

const makeDatOptions = (
  lng?: string
): {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
} => {
  let locale = localesFixed[fallbackLng];
  if (lng) {
    const subCode = lng?.split("-")[0];
    locale = localesFixed[lng] || localesFixed[subCode] || locale;
  }

  return {
    locale,
    weekStartsOn: 1,
  };
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: true,
    },
  });

(i18n.services.formatter as Formatter).add(
  "date&time",
  (value, lng, options) => {
    const dateOpts = makeDatOptions(lng);
    if (typeof value === "string") {
      value = parseISO(value);
    }
    return format(value, "PPpp", dateOpts);
  }
);
(i18n.services.formatter as Formatter).add("date", (value, lng, options) => {
  const dateOpts = makeDatOptions(lng);
  if (typeof value === "string") {
    value = parseISO(value);
  }
  return format(value, "PPPP", dateOpts);
});
(i18n.services.formatter as Formatter).add("datel", (value, lng, options) => {
  if (typeof value === "string") {
    value = parse(value.split("T")[0], "yyyy-MM-dd", new Date(0));
  }
  const dateOpts = makeDatOptions(lng);
  switch (options?.variant) {
    case "dM":
      return format(value, "dd MMM", dateOpts);
    case "dMY":
      return format(value, "dd MMM yyyy", dateOpts);
    case "M":
      return format(value, "MMMM", dateOpts);
    case "MY":
      return format(value, "MMM yyyy", dateOpts);
    case "long":
      return format(value, "EEEE, dd MMMM yyyy", dateOpts);
    case "short":
      return format(value, "dd MMMM yyyy", dateOpts);
    default:
      return format(value, "PPPP", dateOpts);
  }
});
(i18n.services.formatter as Formatter).add("integer", (value, lng, options) => {
  const dateOpts = makeDatOptions(lng);
  if (typeof value === "number") {
    value = value.toLocaleString(lng);
  }
  return format(value, "PPPP", dateOpts);
});

export default i18n;
