import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CgMenu, CgUser, CgSearch } from "react-icons/cg";
import { useAuthenticatedUser } from "../../hooks/AuthenticatedUser";
import { API_URL } from "../../services/HttpClient";
import SmallButton from "../inputs/SmallButton";
import Drawer from "./Drawer";

function NavBar(props: {
  noDrawer?: boolean;
  pendingUpdate: boolean;
  skipWaiting: () => void;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { user, tenant } = useAuthenticatedUser();
  return (
    <>
      <header className="bg-white absolute w-full shadow-lg grid grid-cols-2 md:grid-cols-3 items-center gap-2 p-2 justify-between text-black z-20">
        <div className="flex flex-nowrap gap-2 items-center justify-start">
          <div
            className={`flex justify-center items-center ${
              props.noDrawer ? "" : "hidden lg:flex"
            }`}
          >
            <img
              className="h-10 -mb-2 -mt-2 -ml-2"
              alt="logo"
              src="/logo.svg"
            />
          </div>
          {!props.noDrawer && (
            <SmallButton
              label={t("core.nav.menu")}
              onClick={() => setOpen(true)}
              right
              showSm
              className="relative lg:hidden"
            >
              <>
                <CgMenu size={20} />
                {props.pendingUpdate && (
                  <>
                    <div className="absolute -top-[1px] -right-[1px] bg-blue-400 w-2 h-2 rounded-full animate-ping" />
                    <div className="absolute -top-[1px] -right-[1px] bg-blue-400 w-2 h-2 rounded-full" />
                  </>
                )}
              </>
            </SmallButton>
          )}
        </div>
        <div className="hidden md:block">
          {!props.noDrawer && (
            <div
              className="relative mx-auto w-fit group bg-gray-100 rounded-full cursor-pointer select-none"
              onClick={() => {
                window.dispatchEvent(new Event("onSearch"));
              }}
            >
              <input
                className="bg-transparent outline-none mx-6 p-1 pointer-events-none"
                placeholder="Search..."
                style={{ lineHeight: "0px" }}
              />
              <div className="absolute left-1 inset-y-0 flex items-center pl-1">
                <CgSearch className="m-auto text-gray-500 group-focus-within:text-black" />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-nowrap gap-2 items-center justify-end">
          <span className="font-semibold">
            <div className="text-sm text-black">{user?.displayName}</div>
            <div className="text-[10px] leading-[10px] text-gray-500">
              {tenant?.displayName}
            </div>
          </span>
          <a
            href={API_URL + "/auth/logout"}
            className="rounded-full p-1 cursor-pointer hover:shadow"
          >
            <CgUser />
          </a>
        </div>
        {!props.noDrawer && (
          <>
            <div
              className={`transition-opacity duration-500 ease-in-out ${
                open ? " inset-0 opacity-100" : "-left-full opacity-0"
              } fixed z-40 bg-gray-500/50 backdrop-blur-[2px]`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            />
            <Drawer
              className={`transition-all duration-150 ease-in-out fixed z-50 inset-y-0 ${
                open ? "left-0" : "-left-full"
              }`}
              open={open}
              onClose={() => setOpen(false)}
              pendingUpdate={props.pendingUpdate}
              skipWaiting={props.skipWaiting}
            />
          </>
        )}
      </header>
    </>
  );
}

export default NavBar;
