import React, { useState } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { Entity } from "../model/Entity";

function FavoriteToogle(props: {
  entity: Entity;
  onChange?: (e: Entity) => void;
  toggleFn: (e: Entity) => Promise<Entity>;
}) {
  const [favorite, setFavorite] = useState(props.entity.favorite);

  const toggle = async () => {
    const e = await props.toggleFn(props.entity);
    setFavorite(e.favorite);
    if (props.onChange) props.onChange(e);
  };

  return (
    <button className="p-2 rounded-full hover:text-gray-500" onClick={toggle}>
      {favorite ? <BsBookmarkFill /> : <BsBookmark />}
    </button>
  );
}

export default FavoriteToogle;
