import { AuthenticatedUser } from "../model/User";
import { getCookie, setCookie } from "../utils/Cookies";

const COOKIE_NAME = "user";
export const VERIFY_AUTH_EVENT = "requestAuthValidation";

export class AuthenticatedUserMissing extends Error {
  constructor() {
    super("error:core.401");
  }
}

export function setAuthenticatedUser(config: AuthenticatedUser | null) {
  setCookie({
    key: COOKIE_NAME,
    value: !!config ? JSON.stringify(config) : "",
  });
}

export function useAuthenticatedUserSafe(): AuthenticatedUser | null {
  const cookie = getCookie(COOKIE_NAME);
  if (!cookie?.value) {
    return null;
  }
  return JSON.parse(cookie.value);
}

export function useAuthenticatedUser(): AuthenticatedUser {
  const config = useAuthenticatedUserSafe();
  if (!config) {
    document.dispatchEvent(new Event(VERIFY_AUTH_EVENT));
    throw new AuthenticatedUserMissing();
  }
  return config;
}
