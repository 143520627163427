import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiDelete, FiSave } from "react-icons/fi";
import { Product } from "../model/Product";
import { ProductService } from "../services/ProductService";
import { Category } from "../model/Category";
import Selector from "../../core/components/inputs/Selector";
import MoneyInput from "../../core/components/inputs/MoneyInput";
import { Money } from "../../core/model/Money";
import ProviderSelector from "../../entitymanagement/components/select/ProviderSelector";
import { errorToString } from "../../core/utils/Error";
import deepEqual from "fast-deep-equal/react";

function ProductInfo(props: {
  product: Product;
  onChange: (c: Product) => void;
  onClose?: () => void;
}) {
  // const navigate = useNavigate();
  const { t } = useTranslation(["translation", "error"]);
  const [state, setState] = useState({
    loading: false,
    error: undefined as undefined | string,
  });
  const [product, setProduct] = useState(props.product);
  useEffect(() => {
    setProduct(props.product);
    setState({
      loading: false,
      error: undefined,
    });
  }, [props.product]);

  const labelClass =
    "block font-semibold text-sm text-gray-800 p-3 self-center border-b";
  const divInputClass = "h-full p-1 col-span-2  border-b";
  const inputClass = "p-1 w-full bg-transparent self-center";

  const saveProduct = async () => {
    if (state.loading) return;
    setState({
      loading: true,
      error: undefined,
    });
    try {
      const res = await ProductService.save(product);
      // if (!props.product.id) {
      //   navigate("../../" + res.id, {
      //     replace: true,
      //   });
      //   setProduct(res);
      // }
      setState({
        loading: false,
        error: undefined,
      });
      props.onChange(res);
      if (props.onClose) props.onClose();
    } catch (e: unknown) {
      console.log(e);
      setState({
        loading: false,
        error: errorToString(e),
      });
    }
  };

  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    const fetchCategories = async () => {
      const res = await ProductService.fetchCategories();
      setCategories(res.values);
    };
    fetchCategories();
  }, []);

  const priceCIva = useMemo(
    () =>
      !!product.iva
        ? ({
            currency: product.currency,
            price:
              Math.round(product.price * (1 + product.iva / 100) * 100) / 100,
          } as Money)
        : undefined,
    [product.currency, product.iva, product.price]
  );

  const isEqual = useMemo(
    () => deepEqual(props.product, product),
    [product, props.product]
  );

  return (
    <form
      className="grid grid-cols-3"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        saveProduct();
      }}
    >
      <label className={labelClass}>{t("products.model.name")}</label>
      <div className={divInputClass}>
        <input
          className={inputClass}
          placeholder="n/a"
          value={product?.name || ""}
          onChange={(e) =>
            setProduct({
              ...product,
              name: e.target.value,
            })
          }
        />
      </div>
      <label className={labelClass}>{t("products.model.category")}</label>
      <div className={divInputClass}>
        <Selector
          options={categories.map((x) => ({
            id: x.id,
            label: x.title,
          }))}
          value={product.productcategory}
          onChange={(c) =>
            setProduct({
              ...product,
              productcategory: c as any,
            })
          }
        />
      </div>
      <label className={labelClass}>{t("products.model.provider")}</label>
      <div className={divInputClass}>
        <ProviderSelector
          className={inputClass}
          value={product.providerid}
          onChange={(c) =>
            setProduct({
              ...product,
              providerid: c as any,
            })
          }
        />
      </div>
      <label className={labelClass}>{t("products.model.price")}</label>
      <div className={divInputClass}>
        <MoneyInput
          value={product}
          onChange={(c) =>
            setProduct({
              ...product,
              price: c.price,
              currency: c.currency,
            })
          }
        />
      </div>

      <label className={labelClass}>{t("products.model.iva")}</label>
      <div className={divInputClass}>
        <div
          className={
            inputClass +
            " group outline-2 rounded focus-within:outline outline-blue-600 flex items-center"
          }
        >
          <span className="text-gray-500 pointer-events-none text-sm pl-[2px]">
            %
          </span>
          <input
            className="outline-none pl-[2px]"
            placeholder={t("core.na")}
            value={product?.iva || ""}
            onChange={(c) =>
              setProduct({
                ...product,
                iva: c.target.value as any,
              })
            }
          />
        </div>
      </div>

      {!!priceCIva && (
        <>
          <label className={labelClass}>
            {t("products.model.price_final")}
          </label>
          <div className={divInputClass}>
            <MoneyInput
              value={priceCIva}
              onChange={(c) =>
                setProduct({
                  ...product,
                  price:
                    Math.round((c.price / (1 + product.iva / 100)) * 100) / 100,
                  currency: c.currency,
                })
              }
            />
          </div>
        </>
      )}
      <label className={labelClass}>{t("products.model.date")}</label>
      <div className={divInputClass}>
        <input
          type="date"
          className={inputClass}
          value={(product.date || "").split("T")[0]}
          onChange={(c) =>
            setProduct({
              ...product,
              date: c.target.value,
            })
          }
        />
      </div>

      <label className={labelClass + " h-full"}>
        {t("products.model.notes")}
      </label>
      <div className={divInputClass + " self-start"}>
        <textarea
          className={inputClass}
          placeholder="n/a"
          rows={4}
          value={product?.notes || ""}
          onChange={(e) =>
            setProduct({
              ...product,
              notes: e.target.value,
            })
          }
        />
      </div>
      {(!isEqual || !!state.error) && (
        <div className="col-span-3 flex gap-2 p-2 border-t items-center">
          {!!state.error && (
            <div className="pl-1 text-sm text-red-800">
              {t("error:" + state.error)}
            </div>
          )}
          <div className="flex-grow" />
          {!!product.id && (
            <button
              className="rounded-full hover:shadow border flex items-center gap-1 px-2 py-1 text-gray-700 hover:text-black"
              type="reset"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setProduct(props.product);
              }}
            >
              <span className="font-semibold tracking-tight text-sm">
                Apagar
              </span>
              <FiDelete />
            </button>
          )}
          <button
            className="rounded-full hover:shadow border flex items-center gap-1 px-2 py-1 text-gray-700 hover:text-black"
            type="submit"
          >
            <span className="font-semibold tracking-tight text-sm">
              Guardar
            </span>
            <FiSave />
          </button>
        </div>
      )}
    </form>
  );
}

export default ProductInfo;
