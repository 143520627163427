import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ExpenseType } from "../../model/Expense";

function ExpenseTypeListItem(props: {
  expenseType: ExpenseType;
  onClick?: MouseEventHandler;
}) {
  const { t } = useTranslation();
  return (
    <NavLink
      className="hover:bg-gray-100 p-2 cursor-pointer flex flex-nowrap items-center"
      onClick={props.onClick}
      to={"/expenses/" + props.expenseType.id}
    >
      <div className="flex-grow">
        <h5 className="leading-5 font-semibold">{`${props.expenseType.title}`}</h5>
        <div className="text-sm leading-4 truncate text-gray-500">{`${t(
          "expenses.type.ismensal_value." + props.expenseType.ismensal
        )}`}</div>
      </div>
    </NavLink>
  );
}

export default ExpenseTypeListItem;
