import { CountedResult, Page } from "../../core/model/Pagination";
import { HttpClient, queryToString } from "../../core/services/HttpClient";
import { Entity } from "../model/Entity";
import { Provider, ProviderDebt } from "../model/Provider";

export class ProviderService {
  static async search(
    search: string,
    page: Page
  ): Promise<CountedResult<Provider>> {
    const query = {
      search: search || undefined,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<Provider>>(
      `/providers/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async get(id: string): Promise<Provider> {
    const res = await HttpClient.get<Provider>(`/providers/id?id=${id}`);
    return res.data;
  }
  static async save(provider: Provider): Promise<Provider> {
    const res = await HttpClient.post<Provider>(`/providers`, provider);
    return res.data;
  }
  static async getDebt(
    providerid?: string,
    year?: number
  ): Promise<ProviderDebt> {
    const query = {
      providerid,
      year,
    };
    const res = await HttpClient.get<ProviderDebt>(
      `/envoices/provider/total?${queryToString(query)}`
    );
    return res.data;
  }
  static async toggleFavorite(c: Entity): Promise<Provider> {
    const res = await HttpClient.post<Provider>(
      `/providers/id/${c.id}/favorite`,
      {}
    );
    return res.data;
  }
}
