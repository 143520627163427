import { ApplicationConfiguration } from "../hooks/ApplicationConfig";
import { AuthenticatedUser } from "../model/User";
import { HttpClient } from "./HttpClient";

export class ApplicationConfigService {
  static async sendConfigs(
    config: ApplicationConfiguration
  ): Promise<ApplicationConfiguration> {
    const res = await HttpClient.post<ApplicationConfiguration>(
      `/application/config`,
      config
    );
    return res.data;
  }
  static async getConfig(): Promise<ApplicationConfiguration> {
    const res = await HttpClient.get<ApplicationConfiguration>(
      `/application/config`
    );
    return res.data;
  }
  static async getAuthenticatedUser(): Promise<AuthenticatedUser> {
    const res = await HttpClient.get<AuthenticatedUser>(`/auth/verify`);
    return res.data;
  }
}
