import ContainerPage from "../components/application/ContainerPage";
import ShowAppConfig from "../components/config/ShowAppConfig";

function ApplicationConfigPage(props: {}) {
  return (
    <ContainerPage>
      <ShowAppConfig />
    </ContainerPage>
  );
}

export default ApplicationConfigPage;
