import React from "react";
import { ClientService } from "../../services/ClientService";
import { Client } from "../../model/Client";
import { useTranslation } from "react-i18next";
import ComboBoxAsync from "../../../core/components/inputs/ComboBoxAsync";

function ClientSelector(props: {
  className?: string;
  value?: string;
  placeholder?: string;
  onChange: (c: string | undefined) => void;
}) {
  const { t } = useTranslation();
  return (
    <ComboBoxAsync
      className={props.className}
      fetch={(p, s) => ClientService.search(s || "", p)}
      fetchSingle={ClientService.get}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      getId={(x) => x?.id}
      textBuilder={(v: Client) => v?.name}
      entryBuilder={(value, { active, selected }) => (
        <span className={`relative block truncate font-normal`}>
          <div className="leading-4 font-medium">{value.name}</div>
          <div className="text-[14px] leading-[14px]">
            {`${t("entities.tel")}: ${value.email || t("core.na")}`}
          </div>
          <div className="text-[12px] leading-[12px]">
            {`${t("entities.email")}: ${value.telefone || t("core.na")} `}
          </div>
        </span>
      )}
    />
  );
}

export default ClientSelector;
