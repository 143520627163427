import React from "react";

function ContainerPage(props: { children: JSX.Element[] | JSX.Element }) {
  return (
    <div
      className="p-4 flex flex-col flex-nowrap items-center overflow-hidden"
      style={{ flex: "1 1 auto" }}
    >
      <div
        className="bg-white w-full max-w-6xl overflow-hidden shadow-lg"
        style={{ height: "999999px" }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default ContainerPage;
