import React, { useMemo } from "react";
import { FiHome, FiRefreshCcw, FiSearch, FiX } from "react-icons/fi";
import { IoIosCalendar } from "react-icons/io";
import {
  IoBusiness,
  IoCalculator,
  IoCard,
  IoCart,
  IoPeople,
  IoSettings,
  IoShirt,
} from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SmallButton from "../inputs/SmallButton";

function Drawer(props: {
  className?: string;
  open: boolean;
  onClose?: () => void;
  pendingUpdate: boolean;
  skipWaiting: () => void;
}) {
  const { t } = useTranslation();
  const routes = useMemo(
    () => [
      {
        path: "/",
        icon: <FiHome />,
      },
      {
        path: "/calendar",
        icon: <IoIosCalendar />,
      },
      {
        path: "/clients",
        icon: <IoPeople />,
      },
      {
        path: "/providers",
        icon: <IoBusiness />,
      },
      {
        path: "/employees",
        icon: <IoShirt />,
      },
      {
        path: "/products",
        icon: <IoCart />,
      },
      {
        path: "/expenses",
        icon: <IoCard />,
      },
      {
        path: "/balance",
        icon: <IoCalculator />,
      },
      {
        path: "/configurations",
        icon: <IoSettings />,
      },
    ],
    []
  );
  const { open, pendingUpdate, skipWaiting } = props;

  return (
    <div
      className={`bg-white shadow-xl z-50 w-64 flex flex-col ${
        props.className || ""
      }`}
      onClick={props.onClose}
    >
      <div className="relative border-b mt-2 mx-2 pb-4 lg:hidden">
        <div className="flex justify-center items-center w-full">
          <img
            className="pt-10 lg:pt-0 lg:pb-10 mx-4"
            alt="logo"
            src="/logo.svg"
          />
        </div>
        <h3 className="text-center font-bold text-3xl sr-only">Solestício</h3>
        {!!props.onClose && (
          <div className="absolute top-0 right-0">
            <button
              disabled={!open}
              className="border-b rounded-full p-2"
              onClick={props.onClose}
            >
              <FiX />
            </button>
          </div>
        )}
        <div className="absolute top-0 left-0 lg:hidden">
          <SmallButton
            disabled={!open}
            showSm
            right
            className="border-b rounded-full p-2"
            label={t("core.actions.search")}
            onClick={() => {
              window.dispatchEvent(new Event("onSearch"));
            }}
          >
            <FiSearch />
          </SmallButton>
        </div>
      </div>
      <div className="hidden lg:block border-b p-2 mt-2">
        <div
          className=" rounded px-3 py-2 flex gap-2 items-center cursor-pointer select-none  text-gray-500 hover:text-black hover:bg-gray-100"
          onClick={() => {
            window.dispatchEvent(new Event("onSearch"));
          }}
        >
          <div>
            <FiSearch />
          </div>
          <div className="text-semibold">{t("core.actions.search")}</div>
        </div>
      </div>
      <div className="flex-grow select-none p-2">
        {routes.map(({ path, icon }) => (
          <NavLink
            key={`navlink-${path}`}
            to={path}
            tabIndex={open ? undefined : -1}
            className={({ isActive }) =>
              `block px-3 py-2 font-semibold  hover:bg-gray-100 rounded-lg cursor-pointer ${
                isActive ? "text-black" : "text-gray-500 hover:text-black"
              }`
            }
          >
            <div
              className="inline-block mr-2"
              style={{ verticalAlign: "middle" }}
            >
              {icon}
            </div>
            <span style={{ verticalAlign: "middle" }}>
              {t("core.nav." + path)}
            </span>
          </NavLink>
        ))}
      </div>
      {pendingUpdate && (
        <div className="border-t flex justify-between items-center p-2 gap-2">
          <span className="text-sm leading-4">
            {t("core.info.update_avaliable")}
          </span>
          <SmallButton
            className="relative"
            label={t("core.actions.update")}
            onClick={skipWaiting}
          >
            <>
              <FiRefreshCcw />
              <div className="absolute -top-[1px] -right-[1px] bg-blue-400 w-2 h-2 rounded-full animate-ping" />
              <div className="absolute -top-[1px] -right-[1px] bg-blue-400 w-2 h-2 rounded-full" />
            </>
          </SmallButton>
        </div>
      )}
    </div>
  );
}

export default Drawer;
