import { CountedResult, Page } from "../../core/model/Pagination";
import { HttpClient, queryToString } from "../../core/services/HttpClient";
import { Category } from "../model/Category";
import { Product } from "../model/Product";

export class ProductService {
  static async get(id: string): Promise<Product> {
    const res = await HttpClient.get<Product>(`/products/id?id=${id}`);
    return res.data;
  }
  static async save(client: Product): Promise<Product> {
    const res = await HttpClient.post<Product>(`/products`, client);
    return res.data;
  }
  static async search(
    page: Page,
    search?: string
  ): Promise<CountedResult<Product>> {
    const query = {
      search,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<Product>>(
      `/products/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async fetchCategories(): Promise<CountedResult<Category>> {
    const query: Page = {
      page: 1,
      perPage: 9999999,
    };

    const res = await HttpClient.get<CountedResult<Category>>(
      `/products/categories/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async createCategory(obj: Category): Promise<Category> {
    const res = await HttpClient.post<Category>(`/products/categories`, obj);
    return res.data;
  }
}
