import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../services/HttpClient';

function LoginPage(props: {}) {
    const { t } = useTranslation()
    return (
        <div className='flex flex-col items-center justify-center min-h-screen min-w-full'>
            <h1 className='text-3xl mb-4'>{t('login.wellcome')}</h1>
            <a 
                href={API_URL + '/auth/login'}
                className="px-6 py-2 
                    shadow-lg shadow-blue-200 
                    rounded-xl animate-[pulse_5s_ease-in-out_infinite]
                    bg-black text-white hover:bg-blue-800 hover:animate-none
                    text-lg font-bold tracking-wider">
                {t('login.login')}
            </a>
        </div>
    );
}

export default LoginPage;