import { format, parseISO } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Money, moneyToText } from "../../core/model/Money";
import { Event } from "../model/Event";

function ServiceListItem(props: {
  event: Event;
  onClick?: React.MouseEventHandler;
}) {
  const { t } = useTranslation();
  return (
    <NavLink
      onClick={props.onClick}
      to={`/event/${props.event.id}`}
      className="block hover:bg-gray-100 p-2 cursor-pointer"
    >
      <h5 className="leading-5 font-semibold">{`${props.event.name} - ${
        props.event.meta?.client || ""
      }`}</h5>
      <h6 className="text-sm leading-tight truncate">{`${
        props.event.meta?.room || ""
      } | ${format(parseISO(props.event.date), "yyyy-MM-dd HH:mm")}`}</h6>
      <span className="text-sm text-gray-800 leading-tight truncate">
        {`Total: ${props.event.stats?.quantity || 0} pessoa(s), ${moneyToText(
          props.event.stats || ({} as Money),
          t
        )}`}
      </span>
    </NavLink>
  );
}

export default ServiceListItem;
