import { RadioGroup } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus, FiSave, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ClientSelector from "../../../entitymanagement/components/select/ClientSelector";
import { EventType, Room } from "../../../servicesmanagement/model/Event";
import { EventService } from "../../../servicesmanagement/services/EventService";
import MoneyInput from "../../components/inputs/MoneyInput";
import Selector from "../../components/inputs/Selector";
import SmallButton from "../../components/inputs/SmallButton";
import Loading from "../../components/utils/Loading";
import {
  ApplicationConfiguration,
  setApplicationConfig,
  useApplicationConfigSafe,
} from "../../hooks/ApplicationConfig";
import { ApplicationConfigService } from "../../services/ApplicationConfigService";
import { errorToString } from "../../utils/Error";

function ShowAppConfig(props: { className?: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "error"]);
  const config = useApplicationConfigSafe();
  const [state, setState] = useState({
    value:
      config || ({ fiscalYearStart: 1 } as Partial<ApplicationConfiguration>),
    loading: false,
    error: null as null | string,
    rooms: [] as Room[],
    types: [] as EventType[],
  });

  useEffect(() => {
    const fetch = async () => {
      const [rooms, types] = await Promise.all([
        EventService.fetchRooms(),
        EventService.fetchEventTypes(),
      ]);
      setState({
        ...state,
        rooms: rooms.values,
        types: types.values,
      });
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async () => {
    if (state.loading) return;
    try {
      setState({
        ...state,
        error: null,
        loading: true,
      });

      const res = await ApplicationConfigService.sendConfigs(
        state.value as ApplicationConfiguration
      );
      setApplicationConfig(res);
      navigate(0);
      setState({
        ...state,
        error: null,
        value: res,
        loading: false,
      });
    } catch (e: any) {
      setState({
        ...state,
        error: errorToString(e),
        loading: false,
      });
    }
  };

  const changeItemField = (
    i: number,
    objKey: string,
    eventVal: string | number
  ) => {
    const values: any[] = state.value?.defaultEventReservations || [];
    values[i] = {
      ...(values[i] || {}),
      [objKey]: eventVal,
    };
    setState({
      ...state,
      value: {
        ...state.value,
        defaultEventReservations: values,
      },
    });
  };
  const deleteItem = (i: number) => {
    const values: any[] = state.value?.defaultEventReservations || [];
    values.splice(i, 1);
    setState({
      ...state,
      value: {
        ...state.value,
        defaultEventReservations: values.length === 0 ? undefined : values,
      },
    });
  };

  return (
    <form
      className={
        "max-h-full min-h-full overflow-y-auto flex flex-col " +
        (props.className || "")
      }
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        submit();
      }}
    >
      <div className="p-4 shadow relative">
        <h1 className="text-xl font-bold">{t("configs.title_appconfig")}</h1>
        <div className="text-sm text-gray-500">
          {t("configs.description_appconfig")
            .split("\n")
            .map((x, i) => (
              <div key={`appconfig-title-${i}`} className="leading-4">
                {x}
              </div>
            ))}
        </div>
        <Loading
          className="absolute top-full inset-x-0"
          enabled={state.loading}
        />
      </div>
      <div className="flex flex-col p-4 gap-4 flex-grow">
        <RadioGroup
          disabled={!!config?.fiscalYearStart}
          value={state.value.fiscalYearStart || 1}
          onChange={(fiscalYearStart: any) =>
            setState({
              ...state,
              value: {
                ...state.value,
                fiscalYearStart,
              },
            })
          }
        >
          <RadioGroup.Label className="font-semibold">
            {t("configs.labels.fiscalyearstart") + "*"}
          </RadioGroup.Label>
          <div className="flex flex-wrap gap-2 mt-2">
            {Array.from({ length: 12 }, (x, i) => i)
              .map((_, i) => ({
                month: i + 1,
                label: t("core.datetime.datel", {
                  value: new Date(2000, i, 15),
                  variant: "M",
                }),
              }))
              .map(({ month, label }) => (
                <RadioGroup.Option
                  value={month}
                  key={`appconfig-month-${month}`}
                  style={{ flex: "1 1 0" }}
                  className={({ checked, disabled }) =>
                    `p-2 shadow rounded select-none border text-center cursor-pointer ${
                      checked
                        ? "bg-blue-600 text-white "
                        : disabled
                        ? "bg-gray-100 "
                        : "bg-white "
                    }}`
                  }
                >
                  {label}
                </RadioGroup.Option>
              ))}
          </div>
        </RadioGroup>
        <div className="flex">
          <label className="font-semibold flex-grow">
            {t("configs.labels.defaulteventreservations")}
          </label>
          <SmallButton
            type="button"
            label={t("core.actions.add")}
            onClick={() =>
              setState({
                ...state,
                value: {
                  ...state.value,
                  defaultEventReservations: [
                    ...(state.value?.defaultEventReservations || []),
                    {} as any,
                  ],
                },
              })
            }
          >
            <FiPlus />
          </SmallButton>
        </div>
        <div className="grid grid-cols-3">
          {!!(state.value?.defaultEventReservations || []).length && (
            <>
              <div className="px-2 text-sm font-semibold">
                {t("events.reservations.entries.title")}
              </div>
              <div className="px-2 text-sm font-semibold">
                {t("events.reservations.entries.quantity")}
              </div>
              <div className="px-2 text-sm font-semibold">
                {t("events.reservations.entries.price")}
              </div>
            </>
          )}
          {(state.value?.defaultEventReservations || []).map((r, i) => (
            <div key={`appconfig-der-{i}-label`} className="contents text-sm">
              <div className="h-full p-1 border-b flex flex-nowrap gap-2 col-span-1 self-start">
                <input
                  className="p-1 w-full bg-transparent self-center"
                  placeholder="n/a"
                  value={r.title || ""}
                  onChange={(e) => changeItemField(i, "title", e.target.value)}
                />
                <button
                  type="button"
                  className="hover:text-red-500"
                  onClick={() => deleteItem(i)}
                >
                  <FiTrash />
                </button>
              </div>
              <div className="h-full p-1 border-b flex flex-nowrap gap-2 col-span-1 self-start">
                <input
                  className="p-1 w-full bg-transparent self-center"
                  placeholder="0"
                  value={r.quantity || ""}
                  onChange={(e) =>
                    changeItemField(i, "quantity", e.target.value)
                  }
                />
              </div>
              <div className="h-full p-1 border-b flex flex-nowrap gap-2 col-span-1 self-start">
                <MoneyInput
                  value={r}
                  onChange={(e) => {
                    changeItemField(i, "price", e.price);
                    changeItemField(i, "currency", e.currency);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <h6 className="font-semibold mb-0 pb-0">
          {t("configs.section.events")}
        </h6>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
          <div className="border-b">
            <label className="font-medium flex-grow mb-2 text-sm">
              {t("configs.labels.defaulteventname")}
            </label>
            <input
              className="p-1 w-full bg-transparent self-center"
              placeholder="n/a"
              value={state.value.defaultEventName || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  value: {
                    ...state.value,
                    defaultEventName: e.target.value || undefined,
                  },
                })
              }
            />
          </div>
          <div className="border-b">
            <label className="font-medium flex-grow mb-2 text-sm">
              {t("configs.labels.defaultclient")}
            </label>
            <ClientSelector
              value={state.value.defaultClient}
              placeholder="n/a"
              className="p-1"
              onChange={(e) =>
                setState({
                  ...state,
                  value: {
                    ...state.value,
                    defaultClient: e || undefined,
                  },
                })
              }
            />
          </div>
          <div className="border-b">
            <label className="font-medium flex-grow mb-2 text-sm">
              {t("configs.labels.defaultroom")}
            </label>
            <Selector
              value={state.value.defaultRoom || null}
              className="p-1"
              placeholder="n/a"
              onChange={(e) =>
                setState({
                  ...state,
                  value: {
                    ...state.value,
                    defaultRoom: e || undefined,
                  },
                })
              }
              options={state.rooms.map(({ id, title }) => ({
                id,
                label: title,
              }))}
            />
          </div>
          <div className="border-b">
            <label className="font-medium flex-grow mb-2 text-sm">
              {t("configs.labels.defaulteventtype")}
            </label>
            <Selector
              value={state.value.defaultEventType || null}
              className="p-1"
              placeholder="n/a"
              onChange={(e) =>
                setState({
                  ...state,
                  value: {
                    ...state.value,
                    defaultEventType: e || undefined,
                  },
                })
              }
              options={state.types.map(({ id, title }) => ({
                id,
                label: title,
              }))}
            />
          </div>
        </div>
      </div>
      <div className="p-4 border-t flex items-center">
        {state.error && (
          <div className="text-red-800">{t("error:" + state.error)}</div>
        )}
        <div className="flex-grow" />
        <SmallButton
          type="submit"
          label={t("core.actions.save")}
          showSm
          className="px-4 py-1 text-base rounded"
        >
          <FiSave />
        </SmallButton>
      </div>
    </form>
  );
}

export default ShowAppConfig;
