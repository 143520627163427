import { EventReservationEntries } from "../../servicesmanagement/model/EventReservation";
import { getCookie, setCookie } from "../utils/Cookies";

const COOKIE_NAME = "options";

export type ApplicationConfiguration = {
  fiscalYearStart: number;
  defaultEventReservations?: EventReservationEntries[];
  defaultEventName?: string;
  defaultClient?: string;
  defaultRoom?: string;
  defaultEventType?: string;
};

export class ApplicationConfigurationMissing extends Error {
  constructor() {
    super("error:configs.missing_appconfig");
  }
}

export function setApplicationConfig(config: ApplicationConfiguration | null) {
  setCookie({
    key: COOKIE_NAME,
    value: !!config ? JSON.stringify(config) : "",
  });
}

export function useApplicationConfigSafe(): ApplicationConfiguration | null {
  const cookie = getCookie(COOKIE_NAME);
  if (!cookie?.value) {
    return null;
  }
  return JSON.parse(cookie.value);
}

export function useApplicationConfig(): ApplicationConfiguration {
  const config = useApplicationConfigSafe();
  if (!config) throw new ApplicationConfigurationMissing();
  return config;
}
