import { CountedResult, Page } from "../../core/model/Pagination";
import { HttpClient, queryToString } from "../../core/services/HttpClient";
import { Employe, EmployeePayment, EmployeesWeekStats } from "../model/Employe";
import { Entity } from "../model/Entity";

export class EmployeService {
  static async search(
    search: string,
    page: Page
  ): Promise<CountedResult<Employe>> {
    const query = {
      search: search || undefined,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<Employe>>(
      `/employees/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async get(id: string): Promise<Employe> {
    const res = await HttpClient.get<Employe>(`/employees/id?id=${id}`);
    return res.data;
  }
  static async save(employe: Employe): Promise<Employe> {
    const res = await HttpClient.post<Employe>(`/employees`, employe);
    return res.data;
  }
  static async toggleFavorite(c: Entity): Promise<Employe> {
    const res = await HttpClient.post<Employe>(
      `/employees/id/${c.id}/favorite`,
      {}
    );
    return res.data;
  }

  static async savePayment(
    employep: EmployeePayment
  ): Promise<EmployeePayment> {
    const res = await HttpClient.post<EmployeePayment>(
      `/employeepayments`,
      employep
    );
    return res.data;
  }
  static async deletePayment(employep: EmployeePayment): Promise<true> {
    await HttpClient.delete(`/employeepayments?id=${employep.id}`);
    return true;
  }
  static async paymentsOf(
    refData: Date,
    employeId?: string
  ): Promise<CountedResult<EmployeePayment>> {
    const month = refData.getMonth() + 1;
    const year = refData.getFullYear();
    const query = {
      id: employeId || undefined,
      month,
      year,
    };

    const res = await HttpClient.get<CountedResult<EmployeePayment>>(
      `/employeepayments/month?${queryToString(query)}`
    );
    return res.data;
  }
  static async statsOfWeek(week: Date): Promise<EmployeesWeekStats> {
    const res = await HttpClient.get<EmployeesWeekStats>(
      `/employeepayments/weektotal?date=${week.toISOString()}`
    );
    return res.data;
  }
}
