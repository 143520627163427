import React, { MouseEventHandler } from "react";
import { Provider } from "../../model/Provider";
import { ProviderService } from "../../services/ProviderService";
import EntityListItem from "./EntityListItem";

function ProviderListItem(props: {
  provider: Provider;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <EntityListItem
      baseUrl="/providers"
      entity={props.provider}
      onClick={props.onClick}
      toggleFn={ProviderService.toggleFavorite}
    />
  );
}

export default ProviderListItem;
