import { APIError } from "../utils/Error";

export const queryToString = (q: Record<string, any>) =>
  Object.entries(q)
    .filter(([k, v]) => v !== undefined && v !== null && v !== "")
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

export type IHttpClientResponse<T> = {
  status: number;
  data: T;
  error?: string;
};

export interface IHttpClient {
  get<T>(url: string): Promise<IHttpClientResponse<T>>;
  delete<T>(url: string): Promise<IHttpClientResponse<T>>;
  post<T>(url: string, bodyContent: any): Promise<IHttpClientResponse<T>>;
}

export const API_URL = process.env.REACT_APP_API_URL || "/api";

class HttpClientImpl implements IHttpClient {
  async get<T>(url: string): Promise<IHttpClientResponse<T>> {
    const res = await fetch(API_URL + url, {
      method: "GET",
    });

    const body = await res.json();
    if (body.error) {
      throw new APIError(body.error);
    }
    return {
      status: res.status,
      data: body.data,
    };
  }
  async delete<T>(url: string): Promise<IHttpClientResponse<T>> {
    const res = await fetch(API_URL + url, {
      method: "DELETE",
    });

    const body = await res.json();
    if (body.error) {
      throw new APIError(body.error);
    }
    return {
      status: res.status,
      data: body.data,
    };
  }
  async post<T>(
    url: string,
    bodyContent: any
  ): Promise<IHttpClientResponse<T>> {
    const res = await fetch(API_URL + url, {
      method: "POST",
      body: JSON.stringify(bodyContent),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const body = await res.json();
    if (body.error) {
      throw new APIError(body.error);
    }
    return {
      status: res.status,
      data: body.data,
    };
  }
}

export const HttpClient: IHttpClient = new HttpClientImpl();
