import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Entity } from "../../model/Entity";
import FavoriteToogle from "../FavoriteToogle";

function EntityListItem(props: {
  entity: Entity;
  onClick?: MouseEventHandler;
  toggleFn: (e: Entity) => Promise<Entity>;
  baseUrl: string;
}) {
  const { t } = useTranslation();
  if (props.onClick) {
    return (
      <div
        onClick={props.onClick}
        className="hover:bg-gray-100 p-2 cursor-pointer flex flex-nowrap items-center"
      >
        <div className="flex-grow">
          <h5 className="leading-5 font-semibold">{`${props.entity.name}`}</h5>
          <div className="text-sm leading-4 truncate text-gray-500">{`${t(
            "entities.tel"
          )}: ${props.entity.email || t("core.na")}`}</div>
          <div className="text-sm leading-4 truncate text-gray-500">
            {`${t("entities.email")}: ${
              props.entity.telefone || t("core.na")
            } `}
          </div>
        </div>
        <FavoriteToogle entity={props.entity} toggleFn={props.toggleFn} />
      </div>
    );
  }
  return (
    <NavLink
      onClick={props.onClick}
      to={props.baseUrl + "/" + props.entity.id}
      className="hover:bg-gray-100 p-2 cursor-pointer flex flex-nowrap items-center"
    >
      <div className="flex-grow">
        <h5 className="leading-5 font-semibold">{`${props.entity.name}`}</h5>
        <div className="text-sm leading-4 truncate text-gray-500">{`${t(
          "entities.tel"
        )}: ${props.entity.email || t("core.na")}`}</div>
        <div className="text-sm leading-4 truncate text-gray-500">
          {`${t("entities.email")}: ${props.entity.telefone || t("core.na")} `}
        </div>
      </div>
      <FavoriteToogle entity={props.entity} toggleFn={props.toggleFn} />
    </NavLink>
  );
}

export default EntityListItem;
