import React, { MouseEventHandler } from "react";
import { Employe } from "../../model/Employe";
import { EmployeService } from "../../services/EmployeService";
import EntityListItem from "./EntityListItem";

function EmployeListItem(props: {
  employe: Employe;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <EntityListItem
      baseUrl="/employees"
      entity={props.employe}
      onClick={props.onClick}
      toggleFn={EmployeService.toggleFavorite}
    />
  );
}

export default EmployeListItem;
