import React, {
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CountedResult, Page } from "../model/Pagination";

const EMPTY_SEARCH_TOKEN = "!n/a!";

export function usePagination<T>(props: {
  fetch: (page: Page, search: string) => Promise<T[] | CountedResult<T>>;
  perPage?: number;
  search?: string;
}) {
  const scrollRef = useRef<any>();
  const [search, setSearch] = useState(props.search || "");
  const [state, setState] = useState({
    loading: false as false | number,
    error: undefined as undefined | string,
    lastPage: {} as Record<string, number>,
    page: {
      page: 1,
      perPage: props.perPage || 25,
    } as Page,
  });
  const [values, setValues] = useState<Record<string, Record<string, T[]>>>({});

  const fetchFn = useCallback(async () => {
    const res = props.fetch(state.page, search);
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetch, state.page]);

  const resetPage = () => {
    setSearch("");
    setValues({});
    setState({
      ...state,
      page: {
        page: 1,
        perPage: state.page.perPage,
      },
      lastPage: {},
    });
  };
  const onSearch = (searchstr: string) => {
    searchstr = searchstr || "";
    if (search !== searchstr && scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
    setSearch(searchstr);
    startTransition(() => {
      setState({
        ...state,
        page: {
          page: Math.max(
            1,
            Math.floor(
              Object.entries(values[searchstr || EMPTY_SEARCH_TOKEN] || {})
                .length / state.page.perPage
            ) + 1
          ),
          perPage: state.page.perPage,
        },
      });
    });
  };
  const nextPage = () => {
    if (
      state.loading === false &&
      (!state.lastPage[search || EMPTY_SEARCH_TOKEN] ||
        state.lastPage[search || EMPTY_SEARCH_TOKEN] > state.page.page)
    )
      setState({
        ...state,
        page: {
          page: state.page.page + 1,
          perPage: state.page.perPage,
        },
      });
  };

  useEffect(() => {
    const fetch = async () => {
      if (state.loading !== false) return;
      setState({
        ...state,
        loading: state.page.page,
        error: undefined,
      });
      const res = await fetchFn();

      const isCounted = "count" in res;
      const lst: T[] = isCounted ? res.values : res;
      let lastPage =
        lst.length < state.page.perPage
          ? state.page.page
          : state.lastPage[search || EMPTY_SEARCH_TOKEN];
      if ("count" in res) {
        lastPage = Math.ceil(res.count / state.page.perPage);
      }

      const newValues = {
        ...values[search || EMPTY_SEARCH_TOKEN],
        [state.page.page]: lst,
      };

      setState({
        ...state,
        loading: false,
        error: undefined,
        lastPage: {
          ...state.lastPage,
          [search || EMPTY_SEARCH_TOKEN]: lastPage,
        },
      });
      setValues({
        ...values,
        [search || EMPTY_SEARCH_TOKEN]: newValues,
      });
    };

    const fetchedPageCount =
      Object.entries(values[search || EMPTY_SEARCH_TOKEN] || {}).length + 1;
    if (fetchedPageCount <= state.page.page) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, search]);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
      e.currentTarget.scrollHeight - 300
    ) {
      nextPage();
    }
  };

  const updateElement = (idFn: (v: T) => string, toReplace: T) => {
    const id = idFn(toReplace);
    let found = false;
    const lst = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [
        k,
        Object.fromEntries(
          Object.entries(v).map(([key, value]) => {
            const value1 = [...value];
            const indx = value1.findIndex((x) => idFn(x) === id);
            if (indx !== -1) {
              value1[indx] = toReplace;
              found = true;
            }
            return [key, value1];
          })
        ),
      ])
    );

    if (found) {
      setValues(lst);
    } else {
      resetPage();
    }
  };

  return {
    page: state.page,
    loading: state.loading !== false,
    error: state.error,
    search: search,
    values: Object.values(values[search || EMPTY_SEARCH_TOKEN] || {}).flat(),
    allValues: Object.values(values)
      .map((x) => Object.values(x))
      .flat()
      .flat(),
    resetPage,
    nextPage,
    handleScroll,
    onSearch,
    containerRef: scrollRef,
    updateElement,
  };
}
