import { Dialog } from "@headlessui/react";
import React from "react";
import { FiX } from "react-icons/fi";
import SmallButton from "../inputs/SmallButton";

function MyDialog(props: {
  open: boolean;
  onClose: () => void;
  title: string;
  children: JSX.Element | JSX.Element[];
  additionalHead?: JSX.Element | JSX.Element[];
}) {
  return (
    <Dialog
      as="div"
      static
      className="relative z-50"
      open={props.open}
      onClose={props.onClose}
    >
      <div className="fixed inset-0 backdrop-blur-[2px] bg-gray-500/50 flex items-center justify-center p-4">
        <Dialog.Panel className="shadow-lg rounded bg-white w-md">
          <Dialog.Title
            as="div"
            className="flex text-lg font-semibold py-4 text-left border-b-2 px-4 items-center gap-2"
          >
            <h2 className="mr-20 flex-grow">{props.title}</h2>
            {props.additionalHead}
            <SmallButton className="py-2" onClick={props.onClose}>
              <FiX />
            </SmallButton>
          </Dialog.Title>
          {props.children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default MyDialog;
