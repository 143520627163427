import React from "react";
import { useTranslation } from "react-i18next";
import { Currency, Money } from "../../model/Money";

function MoneyInput(props: {
  className?: string;
  value: Money;
  onChange: (m: Money) => void;
}) {
  const { t } = useTranslation();

  const currency = props.value.currency || Currency.EUR;
  const price = props.value.price || 0;

  return (
    <>
      <label
        htmlFor="price"
        className="sr-only block text-sm font-medium text-gray-700"
      >
        {t("core.labels.money")}
      </label>
      <div className={"relative rounded-md " + (props.className || "")}>
        <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">
            {t("core.money.currencies." + currency.toLowerCase())}
          </span>
        </div>
        <input
          type="number"
          value={(price || 0) + ""}
          onChange={(x) =>
            props.onChange({
              price: x.target.value as any,
              currency,
            })
          }
          className="py-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-[4.2rem] rounded-md"
          placeholder="0.00"
        />
        <div className="absolute inset-y-0 right-0 flex items-center outline-2 outline-blue-600 focus-within:outline rounded-md">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            id="currency"
            value={currency}
            onChange={(x) =>
              props.onChange({
                price,
                currency: x.target.value as Currency,
              })
            }
            className="h-full pl-1 mr-1 border-transparent outline-none bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            {Object.values(Currency).map((c) => (
              <option key={`moneyi-currsel-${c}`} value={c}>
                {c.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default MoneyInput;
