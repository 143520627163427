import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { FiCheck, FiChevronDown } from "react-icons/fi";

type SelectorOptions = { id: string; label: string };

const anyToNullableString = (v: any): string | null =>
  v === null || v === undefined ? null : v + "";

function Selector(props: {
  className?: string;
  placeholder?: string;
  options: SelectorOptions[];
  value: string | null;
  onChange: (v: string | null) => void;
  selectedClassName?: string;
}) {
  const parentDiv = useRef<HTMLDivElement>(null);
  const selectedOption = props.options.find(
    (x) => anyToNullableString(x.id) === anyToNullableString(props.value)
  );
  const selectOptions = (v: SelectorOptions) => {
    props.onChange(v.id);
  };

  return (
    <Listbox value={selectedOption} onChange={selectOptions}>
      <div
        ref={parentDiv}
        className={
          "outline-2 focus-within:outline outline-blue-600 rounded " +
          (props.className || "") +
          " relative"
        }
      >
        <Listbox.Button className="flex items-center w-full cursor-default gap-2 outline-none h-full text-left">
          <div
            className={
              "block truncate flex-grow pl-1 min-h-[1.25rem] " +
              (props.selectedClassName || "")
            }
          >
            {selectedOption?.label || " "}
            {!selectedOption && !!props.placeholder && (
              <span className="text-gray-500">{props.placeholder}</span>
            )}
          </div>
          <div>
            <FiChevronDown
              className="pointer-events-none text-gray-800 mr-1"
              aria-hidden="true"
            />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="z-20 fixed mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{
              width: parentDiv.current?.clientWidth,
            }}
          >
            {props.options.map((option, optionIdx) => (
              <Listbox.Option
                key={optionIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-2 pr-4 ${
                    active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate pr-4 ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {option.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-blue-600">
                        <FiCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default Selector;
