import React, { Suspense } from "react";
import Loading from "./Loading";

function GenericSuspend(props: { children: JSX.Element | JSX.Element[] }) {
  return (
    <Suspense
      fallback={
        <div>
          <Loading />
        </div>
      }
    >
      {props.children}
    </Suspense>
  );
}

export default GenericSuspend;
