import { format } from "date-fns";
import { CountedResult, Page } from "../../core/model/Pagination";
import { HttpClient, queryToString } from "../../core/services/HttpClient";
import { Event, Room, EventType } from "../model/Event";

export class EventService {
  static async get(id: string): Promise<Event> {
    const res = await HttpClient.get<Event>(`/events/id?id=${id}`);
    return res.data;
  }
  static async save(event: Event): Promise<Event> {
    const res = await HttpClient.post<Event>(`/events`, event);
    return res.data;
  }
  static async ofMonth(refData: Date): Promise<CountedResult<Event>> {
    const month = refData.getMonth() + 1;
    const year = refData.getFullYear();

    const res = await HttpClient.get<CountedResult<Event>>(
      `/events/month?month=${month}&year=${year}`
    );
    return res.data;
  }
  static async search(
    page: Page,
    options?: { clientId: string } | { search: string }
  ): Promise<CountedResult<Event>> {
    const query = {
      search: (options as any)?.search,
      clientId: (options as any)?.clientId,
      ...page,
    };

    const res = await HttpClient.get<CountedResult<Event>>(
      `/events/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async next(page: Page, date?: Date): Promise<CountedResult<Event>> {
    const query = {
      date: format(date || new Date(), "yyyy-MM-dd") + "T00:00:00.000Z",
      ...page,
    };
    const res = await HttpClient.get<CountedResult<Event>>(
      `/events/nextsearch?${queryToString(query)}`
    );
    return res.data;
  }
  static async fetchRoom(id: string): Promise<Room> {
    const res = await HttpClient.get<Room>(`/rooms/id?id=${id || ""}`);
    return res.data;
  }
  static async createRoom(room: Room): Promise<Room> {
    const res = await HttpClient.post<Room>(`/rooms`, room);
    return res.data;
  }
  static async fetchRooms(): Promise<CountedResult<Room>> {
    const query: Page = {
      page: 1,
      perPage: 9999999,
    };

    const res = await HttpClient.get<CountedResult<Room>>(
      `/rooms/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async fetchEventType(id: string): Promise<EventType> {
    const res = await HttpClient.get<EventType>(
      `/events/types/id?id=${id || ""}`
    );
    return res.data;
  }
  static async fetchEventTypes(): Promise<CountedResult<EventType>> {
    const query: Page = {
      page: 1,
      perPage: 9999999,
    };

    const res = await HttpClient.get<CountedResult<EventType>>(
      `/events/types/search?${queryToString(query)}`
    );
    return res.data;
  }
  static async createEventType(room: EventType): Promise<EventType> {
    const res = await HttpClient.post<EventType>(`/events/types`, room);
    return res.data;
  }
}
