import { TFunction } from "i18next";

export enum Currency {
  EUR = "EUR",
}

export type Money = {
  currency: Currency;
  price: number;
};

export type MoneyIva = {
  currency: Currency;
  price: number;
  iva: number;
};

export const moneyToText = (money: Money, t: TFunction) =>
  t("core.money.value", {
    price: money?.price || 0,
    formatParams: {
      price: {
        currency: money?.currency || Currency.EUR,
      },
    },
  });

export const basePriceToFinal = (money: MoneyIva): Money => ({
  currency: money.currency,
  price: Math.round(money.price * (1 + money.iva / 100) * 100) / 100,
});
