import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { usePagination } from "../../core/hooks/Pagination";
import { ProductService } from "../services/ProductService";
import { Product } from "../model/Product";
import { basePriceToFinal, moneyToText } from "../../core/model/Money";
import Loading from "../../core/components/utils/Loading";
import ProductInfo from "../components/ProductInfo";
import { FiCopy, FiEdit2, FiPlus } from "react-icons/fi";
import SmallButton from "../../core/components/inputs/SmallButton";
import ContainerPage from "../../core/components/application/ContainerPage";
import MyDialog from "../../core/components/utils/MyDialog";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";

export const PRODUCTPAGE_PRODUCT_QUERY = "product";
export const PRODUCTPAGE_CLONEPRODUCT_QUERY = "clone";

function ProductsPage(props: {}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [categories, setCategories] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetch = async () => {
      const res = await ProductService.fetchCategories();
      setCategories(Object.fromEntries(res.values.map((x) => [x.id, x.title])));
    };
    fetch();
  }, []);

  const {
    values,
    loading,
    search,
    onSearch,
    handleScroll,
    containerRef,
    updateElement,
    allValues,
  } = usePagination<Product>({
    fetch: (p, s) => ProductService.search(p, s),
    perPage: 300,
    search: searchParams.get("search") || undefined,
  });

  const tbCellClass = "px-2 py-1";

  const [selectedProductId, setSelectedProductId] = useState<
    string | null | undefined
  >(searchParams.get(PRODUCTPAGE_PRODUCT_QUERY) || undefined);
  const selectedProduct: Product | null | undefined = useMemo(() => {
    if (selectedProductId === null) return null;
    if (selectedProductId === undefined) return undefined;
    const productStr = selectedProductId.toString();
    return (
      // eslint-disable-next-line eqeqeq
      allValues.find((x) => x.id == productStr) || undefined
    );
  }, [selectedProductId, allValues]);
  const setSelectedProduct = (product: Product | null | undefined) => {
    setSelectedProductId(product === null ? null : product?.id || undefined);
  };

  const [cloning, setCloning] = useState<Partial<Product>>();

  const cloneProduct = (product: Product) => {
    setCloning(product);
  };

  useEffect(() => {
    if (!!selectedProductId) {
      searchParams.set(PRODUCTPAGE_PRODUCT_QUERY, selectedProductId);
    } else {
      searchParams.delete(PRODUCTPAGE_PRODUCT_QUERY);
    }
    if (!!search) {
      searchParams.set("search", search);
    } else {
      searchParams.delete("search");
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId, search]);

  return (
    <ContainerPage>
      <>
        {selectedProduct !== undefined && (
          <MyDialog
            open={true}
            onClose={() => setSelectedProduct(undefined)}
            title={t(
              !!selectedProduct?.id
                ? "products.title_update"
                : "products.title_create"
            )}
          >
            <ProductInfo
              key={`proddia-${
                selectedProduct?.id || selectedProduct?.name || "new"
              }`}
              product={selectedProduct || ({} as any)}
              onChange={(v) => {
                updateElement((v) => v.id, v);
              }}
              onClose={() => setSelectedProduct(undefined)}
            />
          </MyDialog>
        )}
        {cloning !== undefined && (
          <MyDialog
            open={true}
            onClose={() => setCloning(undefined)}
            title={t("products.title_clone")}
          >
            <ProductInfo
              key={`clone-${cloning.id}`}
              product={
                {
                  ...cloning,
                  id: undefined,
                  date: format(new Date(), "yyyy-MM-dd"),
                } as any
              }
              onChange={(v) => {
                setCloning(undefined);
                updateElement((v) => v.id, v);
              }}
              onClose={() => setCloning(undefined)}
            />
          </MyDialog>
        )}
      </>
      <div className="h-full flex flex-col flex-nowrap max-h-full overflow-hidden">
        <div className="flex flex-wrap items-center py-2 px-4 gap-4 justify-between">
          <h2 className="font-bold text-lg">{t("products.title_list")}</h2>
          <div className="flex-grow" />
          <div className="group">
            <div className=" border-b group-focus-within:border-black flex items-center gap-1">
              <div className="text-gray-500 group-focus-within:text-black inline">
                <IoSearch />
              </div>
              <input
                className="flex-grow outline-none bg-transparent p-1 text-gray-500 group-focus-within:text-black"
                placeholder={t("core.search")}
                value={search}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="sm:hidden flex-grow" />
          <SmallButton
            className=""
            showSm
            label={t("core.actions.add")}
            onClick={() => setSelectedProduct(null)}
          >
            <FiPlus />
          </SmallButton>
        </div>
        <div
          className="overflow-y-auto h-0"
          style={{ flex: "1 1 auto" }}
          ref={containerRef}
          onScroll={(e) => {
            handleScroll(e);
          }}
        >
          <table
            className="w-full overflow-y-hidden sticky top-0"
            cellSpacing={0}
            cellPadding={0}
          >
            <thead className="sticky top-0 ">
              <tr className="border-b bg-white">
                <th className="text-sm font-semibold p-2 text-left">
                  {t("products.model.name")}
                </th>
                <th className="text-sm font-semibold p-2 text-center">
                  {t("products.model.category")}
                </th>
                <th className="text-sm font-semibold p-2 text-center">
                  {t("products.model.provider")}
                </th>
                <th className="text-sm font-semibold p-2 text-center">
                  {t("products.model.price")}
                </th>
                <th className="text-sm font-semibold p-2 text-center">
                  {t("products.model.price_civa")}
                </th>
                <th className="text-sm font-semibold p-2 text-center min-w-[100px]">
                  {t("products.model.date")}
                </th>
              </tr>
              <tr>
                <th colSpan={10000000} className="relative">
                  <Loading
                    className="absolute bg-white -bottom-full inset-x-0"
                    enabled={loading}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="divide-y">
              {!loading && !values.length && (
                <tr>
                  <td colSpan={9999} className="p-2 text-gray-500 text-sm">
                    {t("core.info.not_found_list")}
                  </td>
                </tr>
              )}
              {values.map((x) => (
                <tr
                  key={`products-item-${x.id}`}
                  className="even:bg-gray-50 hover:bg-gray-100"
                >
                  <td
                    className={
                      tbCellClass + " flex items-center flex-nowrap gap-1"
                    }
                  >
                    <button
                      className="text-gray-500 hover:text-black"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedProduct(x);
                        e.preventDefault();
                      }}
                    >
                      <FiEdit2 size={10} />
                    </button>
                    <button
                      className="text-gray-500 hover:text-black"
                      onClick={(e) => {
                        cloneProduct(x);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <FiCopy size={10} />
                    </button>
                    <span>{x.name}</span>
                  </td>
                  <td className={tbCellClass + " text-center"}>
                    {categories[x.productcategory] || "-"}
                  </td>
                  <td className={tbCellClass + " text-center"}>
                    {x.meta?.provider || x.providerid}
                  </td>
                  <td className={tbCellClass + " text-center"}>
                    {moneyToText(x, t)}
                  </td>
                  <td className={tbCellClass + " text-center"}>
                    {moneyToText(basePriceToFinal(x), t)} ({x.iva}%)
                  </td>
                  <td className={tbCellClass + " text-center"}>{x.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ContainerPage>
  );
}

export default ProductsPage;
